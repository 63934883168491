@mixin css
{
    //############## COMPONENT FLOW ##############
    component-flow
    {
        component-flow-steps
        {
            .step
            {
                @include step-navigation-coloring('',
                                                  '');

                &.finished
                {
                    @include step-navigation-coloring('active-',
                                                      'active-');

                    .step-index
                    {
                        cursor: pointer;
                    }
                }

                &.active
                {
                    @include step-navigation-coloring('finished-',
                                                      'finished-');
                }
            }
        }
    }
}

@mixin step-navigation-coloring($backgroundName,
                                $foregroundName)
{
    .step-index
    {
        background-color: var(--componentFlowSteps-#{$backgroundName}background);
        color: var(--componentFlowSteps-#{$foregroundName}foreground);
    }

    .step-line
    {
        border-bottom-color: var(--componentFlowSteps-#{$backgroundName}background);
        border-top-color: var(--componentFlowSteps-#{$foregroundName}foreground);
    }
}