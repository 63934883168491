@use '../vars';

@mixin css
{
    //############## TITLED DIALOG ##############

    .scrollable-content.cdk-overlay-pane
    {
        .mat-mdc-dialog-surface
        {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            movable-titled-dialog
            {
                flex: 1;
                min-height: 0;
                display: flex;
                flex-direction: column;

                .titled-dialog-content
                {
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    titled-dialog,
    movable-titled-dialog,
    .static-dialog
    {
        box-shadow: 0 0 3px var(--dialog-border);

        .titled-dialog-title
        {
            border-top-left-radius: vars.$borderRadius;
            border-top-right-radius: vars.$borderRadius;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: var(--dialog-title-foreground);
            background-color: var(--dialog-title-background);

            .close-dialog
            {
                &:hover
                {
                    background-color: var(--dialog-closeButton-hover-background);
                    color: var(--dialog-closeButton-hover-foreground);
                }
            }
        }

        .titled-dialog-content
        {
            border-bottom-left-radius: vars.$borderRadius;
            border-bottom-right-radius: vars.$borderRadius;
            background-color: var(--dialog-content-background);
            color: var(--dialog-content-foreground);
        }
    }

    //############## DATE TIME PICKER ##############

    date-time-picker
    {
        z-index: 1100;
    }

    //############## TOOLTIP ##############

    tooltip-popup
    {
        z-index: 19876;
    }

    //############## DIALOG ##############

    mat-dialog-container
    {
        padding: 0;
        overflow: visible;
    }
}