@use 'externalStyle';
@use 'scss/fonts';
@use 'scss/app';
@use 'scss/mixins';
@use 'scss/vars';

// ---------------------- COMMON ----------------------
*, *:focus
{
    outline: none;
}

*
{
    box-sizing: border-box;
}

html
{
    height: 100%;
    @include mixins.fluid-type(vars.$rootMinFontSizeWidth, vars.$rootMaxFontSizeWidth, vars.$rootMinFontSize, vars.$rootMaxFontSize);
}

body
{
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: 100%;

    &.iframe
    {
        app
        {
            router-outlet+*, router-outlet+*+*
            {
                padding: 0;
            }
        }
    }
}

app
{
    display: flex;
    height: 100vh;
    flex-direction: column;

    router-outlet+*, router-outlet+*+*
    {
        @extend .thin-scrollbar;

        overflow-y: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
        min-width: 0;
        padding: 0 5% 5px;

        &.scrollable
        {
            overflow-y: scroll;
        }
    }
}

.no-ie, .no-grid
{
    display: none;
}

.no-arrow
{
    .no-ie
    {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.7em;
    }

    app
    {
        display: none;
    }
}

.no-cssgrid.arrow
{
    .no-grid
    {
        display: block;
    }
}

// ---------------------- SPLASH SCREEN ----------------------

.loading-indicator
{
    background: url('external/images/keycloak-bg.png') no-repeat center center fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;

    .loading-header
    {
        margin-bottom: 40px;
        margin-top: 20px;
        text-align: center;
        color: #ededed;

        .loading-title
        {
            font-family: "Inter", Helvetica, Arial, sans-serif;
            font-size: 29px;
            text-transform: uppercase;
            letter-spacing: 3px;
            line-height: 1.2em;
            padding: 62px 10px 20px;
            white-space: normal;
        }
    }

    .loading-card
    {
        border-top: 4px solid;
        border-color: #0066CC;
        background-color: #FFFFFF;
        width: 500px;
        height: 323px;
        margin-left: auto;
        margin-right: auto;
    }

    .loading-spinner
    {
        border: 4px solid #eee;
        border-bottom: none;
        border-top-left-radius: 48px;
        border-top-right-radius: 48px;
        margin-top: 20px;
        height: 24px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 48px;
        animation-name: rotate-loading;
        animation-duration: 450ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: calc(50%) calc(50% + 12px);
    }
}

@keyframes rotate-loading
{
    0%
    {
        transform: rotate(0deg);
    }
    100%
    {
        transform: rotate(360deg);
    }
}