@mixin css
{
    //############## MAIN MENU - GLOBAL ##############

    .submenu-item
    {
        background-color: var(--mainMenu-background);
    }

    //############## MAIN MENU ##############

    main-menu
    {
        background-color: var(--mainMenu-background);
        background-image: linear-gradient(to top, var(--mainMenu-background) 0, var(--mainMenu-backgroundGradient) 100%);
        color: var(--mainMenu-foreground);

        .main-item
        {
            &:hover
            {
                backdrop-filter: brightness(94%);
            }
            
            &:active
            {
                background-color: var(--mainMenu-active-background);
                color: var(--mainMenu-active-foreground);
                box-shadow: 0 0 4px var(--mainMenu-active-background);
            }

            &.active
            {
                // color: color.mix($menuBackground, map.get($theme, mainMenuForegroundActive), $weight: 20%);
                color: var(--mainMenu-active-foreground);
            }

            div
            {
                background-image: linear-gradient(to bottom, var(--mainMenu-background) 0, var(--mainMenu-backgroundGradient) 100%);

                @media screen and (max-width: var(--responsive-threshold-md))
                {
                    background-image: none;
                }

                .submenu-item
                {
                    padding: 5px 10px;
                    color: var(--mainMenu-foreground);

                    &:hover
                    {
                        background-color: var(--mainMenu-backgroundGradient);
                    }

                    &.active
                    {
                        // color: color.mix($menuBackground, map.get($theme, mainMenuForegroundActive), $weight: 20%);
                        color: var(--mainMenu-active-foreground);
                    }
                }
            }
        }
    }
}