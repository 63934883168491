@use '../vars';

@mixin css
{
    $thinResponsiveWidthMax: 768px;
    $normalResponsiveWidthMin: 769px;

    @media screen and (max-width: $thinResponsiveWidthMax)
    {
        app
        {
            router-outlet+*, router-outlet+*+*
            {
                padding: 0;
            }
        }

        .mobile-no-rounding
        {
            border-radius: 0;
        }

        .mobile-no-margin
        {
            margin: 0;
        }

        .mobile-hidden
        {
            display: none;
        }
    }

    @media screen and (min-width: $normalResponsiveWidthMin)
    {
        .desktop-hidden
        {
            display: none;
        }
    }

    //############## RESPONSIVE MISC ##############

    .visible-md
    {
        display: none;

        @media screen and (max-width: vars.$responsiveThresholdMd)
        {
            display: block;
        }
    }
}