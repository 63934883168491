@mixin css
{
    $blood-presure: #ff0000cc;
    $blood-dia-presure: #ffd900cc;
    $respiration-rate: #d400ffcc;
    $temperature: #ff7b00cc;
    $temperature-ambient: #ff2600cc;
    $saturation: #0044ffb2;
    $heart-rate: #1eff00b2;
    $position: #000000b2;
    $bodyPosition: #ff006a9f;
    $weight: #8400ff9d;
    $height: #ff00d49d;
    $rotation: #8a32009d;

    // METRICS
    $bodyTemp: #FFB300;
    $ambientTemp: #0033FF;
    $bodyHeight: #FF4D00;
    $glucosePreMeal: #FF00EB;
    $glucosePostMeal: #FF6600;
    $glucose: #57FF00;
    $bloodPressure: #FF00D5;
    $systolicBP: #FF8000;
    $diastolicBP: #0066FF;
    $heartRate: #00FF4D;
    $heartRatePulseox: #8000FF;
    $oxygenSaturation: #FF3300;
    $pulseOximetryStu: #D500FF;
    $physicalMovements: #FF0000;
    $bodyPosition: #FFB300;
    $sensorData: #00FF99;
    $spirometryStudy: #FF8000;
    $fvcVol: #FF0033;
    $fev1: #FFB300;
    $pefAirway: #FFFF00;
    $fev1Fvc: #FF3300;
    $gasFF25: #80FF00;
    $gasFF50: #FF00FF;
    $gasFF75: #00FF66;
    $gasFF2575: #00FF33;
    $bodySurfaceTemp: #B3FF00;
    $earTemp: #FF00FF;
    $ecgStudy: #B3FF00;
    $ecgInterpretation: #FFB300;
    $fvcVolPred: #D500FF;
    $fev1Pred: #FF4D00;
    $pefAirwayPred: #FF00D5;
    $fev1FvcPred: #FF3300;
    $gasFF25Pred: #FF8000;
    $gasFF50Pred: #FF00EB;
    $gasFF75Pred: #FF0000;
    $gasFF2575Pred: #FF6600;
    $insulinRegular: #0066FF;
    $insulinUltralente: #FFB300;
    $questionnaireResponse: #FF4D00;
    $meanScore: #80FF00;
    $totalScore: #FF3300;
    $bodyWeight: #adad00;
    $bodyWater: #00FF33;
    $bodyFat: #FF8000;
    $bodyFatMass: #FF0033;
    $bodyMuscleMass: #FF0000;
    $bodyMuscle: #FF00D5;
    $boneMass: #0033FF;
    $bmi: #57FF00;
    $impedance: #FF6600;
    $medication: #FF00FF;
    $exercise: #D500FF;
    $rehabilitation: #B3FF00;
    $walking: #FFB300;
    $running: #FFFF00;
    $swimming: #FF8000;
    $reading: #00FF66;
    $cycling: #00FF4D;
    $computerGames: #80FF00;
    $fruits: #FFB300;
    $vegetables: #FF00EB;
    $breadCerealsPotatoes: #0066FF;
    $meatFishAlts: #FF3300;
    $fattySugaryFoods: #FF00D5;
    $milkDairyFoods: #FF0000;
    $water: #FF4D00;
    $alcohol: #D500FF;
    $sleep: #FF00FF;

    %with-transition
    {
        transition: all 400ms;
    }

    @include generate-measured-value-color('BPS', $blood-presure);
    @include generate-measured-value-color('TMP', $temperature);
    @include generate-measured-value-color('29463-7', $weight);
    @include generate-measured-value-color('8462-4', $blood-presure);
    @include generate-measured-value-color('8480-6', $blood-dia-presure);
    @include generate-measured-value-color('8480-6', $blood-dia-presure);
    @include generate-measured-value-color('3137-7', $height);
    @include generate-measured-value-color('8867-4', $heart-rate);
    @include generate-measured-value-color('11289-6', $temperature);
    @include generate-measured-value-color('60832-3', $temperature-ambient);
    @include generate-measured-value-color('RR', $respiration-rate);
    @include generate-measured-value-color('SAT', $saturation);
    @include generate-measured-value-color('ROT', $rotation);
    @include generate-measured-value-color('HR', $heart-rate);
    @include generate-measured-value-color('position', $position);
    @include generate-measured-value-color('body_position', $bodyPosition);

    // METRICS
    @include generate-measured-value-color('11289-6', $bodyTemp);
    @include generate-measured-value-color('60832-3', $ambientTemp);
    @include generate-measured-value-color('3137-7', $bodyHeight);
    @include generate-measured-value-color('40193-5', $glucosePreMeal);
    @include generate-measured-value-color('53094-9', $glucosePostMeal);
    @include generate-measured-value-color('14743-9', $glucose);
    @include generate-measured-value-color('18684-1', $bloodPressure);
    @include generate-measured-value-color('8480-6', $systolicBP);
    @include generate-measured-value-color('8462-4', $diastolicBP);
    @include generate-measured-value-color('8867-4', $heartRate);
    @include generate-measured-value-color('8889-8', $heartRatePulseox);
    @include generate-measured-value-color('59408-5', $oxygenSaturation);
    @include generate-measured-value-color('20081-6', $pulseOximetryStu);
    @include generate-measured-value-color('45553-5', $physicalMovements);
    @include generate-measured-value-color('8361-8', $bodyPosition);
    @include generate-measured-value-color('55188-7', $sensorData);
    @include generate-measured-value-color('18759-1', $spirometryStudy);
    @include generate-measured-value-color('19870-5', $fvcVol);
    @include generate-measured-value-color('20150-9', $fev1);
    @include generate-measured-value-color('33452-4', $pefAirway);
    @include generate-measured-value-color('19926-5', $fev1Fvc);
    @include generate-measured-value-color('65821-1', $gasFF25);
    @include generate-measured-value-color('65822-9', $gasFF50);
    @include generate-measured-value-color('65823-7', $gasFF75);
    @include generate-measured-value-color('19927-3', $gasFF2575);
    @include generate-measured-value-color('61008-9', $bodySurfaceTemp);
    @include generate-measured-value-color('76011-6', $earTemp);
    @include generate-measured-value-color('11524-6', $ecgStudy);
    @include generate-measured-value-color('71577-1', $ecgInterpretation);
    @include generate-measured-value-color('19869-7', $fvcVolPred);
    @include generate-measured-value-color('20149-1', $fev1Pred);
    @include generate-measured-value-color('69974-4', $pefAirwayPred);
    @include generate-measured-value-color('19925-7', $fev1FvcPred);
    @include generate-measured-value-color('65821-1-p', $gasFF25Pred);
    @include generate-measured-value-color('65822-9-p', $gasFF50Pred);
    @include generate-measured-value-color('65823-7-p', $gasFF75Pred);
    @include generate-measured-value-color('69971-0', $gasFF2575Pred);
    @include generate-measured-value-color('4287-9', $insulinRegular);
    @include generate-measured-value-color('4289-5', $insulinUltralente);
    @include generate-measured-value-color('74465-6', $questionnaireResponse);
    @include generate-measured-value-color('91147-9', $meanScore);
    @include generate-measured-value-color('93112-1', $totalScore);
    @include generate-measured-value-color('29463-7', $bodyWeight);
    @include generate-measured-value-color('73705-6', $bodyWater);
    @include generate-measured-value-color('77233-5', $bodyFat);
    @include generate-measured-value-color('73708-0', $bodyFatMass);
    @include generate-measured-value-color('73964-9', $bodyMuscleMass);
    @include generate-measured-value-color('73965-6', $bodyMuscle);
    @include generate-measured-value-color('100225-2', $boneMass);
    @include generate-measured-value-color('39156-5', $bmi);
    @include generate-measured-value-color('75920-9', $impedance);
    @include generate-measured-value-color('8677-7', $medication);
    @include generate-measured-value-color('55411-3', $exercise);
    @include generate-measured-value-color('97038-4', $rehabilitation);
    @include generate-measured-value-color('82948-1', $walking);
    @include generate-measured-value-color('93817-5', $running);
    @include generate-measured-value-color('93816-7', $swimming);
    @include generate-measured-value-color('82230-4', $reading);
    @include generate-measured-value-color('93818-3', $cycling);
    @include generate-measured-value-color('28414-1', $computerGames);
    @include generate-measured-value-color('80457-5', $fruits);
    @include generate-measured-value-color('80459-1', $vegetables);
    @include generate-measured-value-color('80445-0', $breadCerealsPotatoes);
    @include generate-measured-value-color('75284-0', $meatFishAlts);
    @include generate-measured-value-color('9066-2', $fattySugaryFoods);
    @include generate-measured-value-color('81674-4', $milkDairyFoods);
    @include generate-measured-value-color('9102-5', $water);
    @include generate-measured-value-color('74013-4', $alcohol);
    @include generate-measured-value-color('93832-4', $sleep);

    chart-data-range,
    hub-chart-data-range
    {
        circle.data
        {
            stroke-width: 1px;
            stroke: var(--chartDataRange-circle-stroke);
        }

        .data-position rect
        {
            @extend %with-transition;

            fill: $position;
        }

        .legend
        {
            .inactive
            {
                opacity: 0.45;

                &>.legend-color
                {
                    background-color: var(--chartDataRange-legend-inactive-background);
                }
            }
        }
    }

    chart-tooltip
    {
        .tooltip-container
        {
            background-color: var(--chartTooltip-container-background);
        }
    }
}

@mixin generate-measured-value-color($measuredValueName, $measuredValueColor)
{
    chart-data-range,
    hub-chart-data-range
    {
        circle.data-#{$measuredValueName}
        {
            fill: $measuredValueColor;
        }

        .data-#{$measuredValueName}
        {
            path
            {
                @extend %with-transition;

                stroke: $measuredValueColor;
                fill: $measuredValueColor;
            }
        }

        .data-area
        {
            opacity: 0.3;
            filter: brightness(.6);
        }

        .line-data-#{$measuredValueName}
        {
            &.data-dashed
            {
                path
                {
                    stroke-dasharray: 2;
                    opacity: 0.6;
                    filter: brightness(.7);
                }
            }

            path
            {
                @extend %with-transition;

                stroke: $measuredValueColor;
                fill: transparent;
            }
        }

        .line-data-area-#{$measuredValueName}
        {
            path
            {
                @extend %with-transition;

                stroke: transparent;
                fill: $measuredValueColor;
            }
        }

        .y-legend
        {
            &.y-legend-#{$measuredValueName}
            {
                fill: $measuredValueColor;
            }
        }

        .legend
        {
            .legend-#{$measuredValueName}
            {
                background-color: $measuredValueColor;
            }
        }
    }

    chart-tooltip
    {
        .text-#{$measuredValueName}
        {
            color: $measuredValueColor;
        }
    }
}