@use 'sass:meta';

@use 'misc' as misc;
@use 'parts/mobile.overrides' as mobileOverrides;
@use 'mixins';

@use 'parts/alerts' as alerts;
@use 'parts/blocks' as blocks;
@use 'parts/buttons' as buttons;
@use 'parts/charts' as charts;
@use 'parts/common.components' as commonComponents;
@use 'parts/common' as common;
@use 'parts/componentFlow' as componentFlow;
@use 'parts/forms' as forms;
@use 'parts/grid' as grid;
@use 'parts/menu' as menu;
@use 'parts/merania' as merania;
@use 'parts/sessionModals' as sessionModals;
@use 'parts/titles' as titles;

@use '@anglr/datetime/styles' as datetime;

//---------------------- PAGE STYLING ----------------------
.app-page
{
    font-family: "Inter";
    user-select: none;
    background-color: var(--page-background);
    color: var(--page-foreground);

    //############## PACKAGES ##############

    @include datetime.buildThemeCss;
    @include meta.load-css('../../node_modules/@anglr/common/styles/light');

    //############## SIZES ##############

    @include mixins.buildSizes;

    //############## SCROLLBAR ##############

    @include mixins.thin-scrollbar-color('--page-scrollbar');

    //############## PARTS ##############

    @include alerts.css;
    @include blocks.css;
    @include buttons.css;
    @include charts.css;
    @include commonComponents.css;
    @include common.css;
    @include componentFlow.css;
    @include forms.css;
    @include grid.css;
    @include menu.css;
    @include merania.css;
    @include sessionModals.css;
    @include titles.css;

    //############## MISC ##############
    @include misc.css;
    
    //############## MOBILE OVERRIDE ##############
    @include mobileOverrides.css;

}