@mixin css
{
    //############## MERANIA DETAIL ##############

    merania-detail
    {
        .details-container.ng-animating
        {
            @for $i from 1 through 40
            {
                &>*:nth-child(#{$i})
                {
                    transform-origin: center ($i * -105%);
                    z-index: ($i * -1);
                    position: relative;
                }
            }
        }

        number-device-detail,
        position-device-detail
        {
            .measured-value
            {
                width: 130px;
                text-align: right;
                font-weight: bold;
                font-style: italic;
            }

            ng-grid
            {
                font-size: 0.8em;
            }
        }

        device
        {
            border-width: 2px;
            border-style: solid;
            border-color: transparent;

            &.active
            {
                border-color: var(--meraniaDetail-activeDevice-border);
            }
        }

        measured-value
        {
            border-width: 1px;
            border-style: dashed;

            border-color: transparent;

            &.active
            {
                border-color: var(--meraniaDetail-activeMeasurement-border);
            }
        }
    }
}