@mixin css
{
    //############## DIAGNOSIS SELECTION ##############

    diagnosis-selection
    {
        .values-group
        {
            border-color: var(--diagnosisSelection-valueGroup-border);
            background-color: var(--iagnosisSelection-valueGroup-background);
            box-shadow: 0 0 2px inset var(--diagnosisSelection-valueGroup-border);

            label
            {
                &.selected-dg
                {
                    background-color: var(--diagnosisSelection-selectedDg-background);
                    color: var(--diagnosisSelection-selectedDg-foreground);
                }
            }
        }
    }
}